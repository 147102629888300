<template>
  <div
    class="
      footer
      flex flex-col
      md:flex-row
      justify-between
      bg-footerDarkBlue
      px-3
      sm:px-5
      md:px-10
      lg:px-20
      xl:px-40
      py-5
      text-white
    "
  >
    <div class=" md:w-2/3 mb-2">
      <div class="logo flex items-center mb-2">
        <img :src="require('../img/DetayAkademi_WhiteLogo.png')" alt="" />
      </div>
      <p>
        Çalışanlarımız Tarafından Birçok Alanda Hazırlanan Yararlı
        İçeriklerimizi İnceleyebilir,<br />
        Eğitimlerimize Başvurabilirsiniz
      </p>
    </div>
    <div class="social-media flex flex-col text-right">
      <div class="bg-ye">
        <p>Bizi Takip Edin</p>
        <div class="text-2xl mt-2">
          <i
            class="fab fa-instagram text-red-500 mr-2 bg-white p-2 rounded-full"
          ></i>
          <i
            class="fab fa-twitter text-blue-500 mr-2 bg-white p-2 rounded-full"
          ></i>
          <i
            class="fab fa-facebook text-blue-500 mr-2 bg-white p-2 rounded-full"
          ></i>
          <i
            class="fab fa-linkedin text-blue-500 bg-white p-2 rounded-full"
          ></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.logo{
  max-width: 250px;
}
</style>
<template>
  <div class="">
    <app-section></app-section>
  </div>
</template>
<script>
import Section from "../components/Section";

export default {
  components: {
    appSection: Section,
  },
};
</script>
<style scoped>
</style>

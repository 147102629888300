<template>
  <div class="card text-center " data-aos="zoom-in">
    <figure class="px-5 pt-5 "><img src="../img/card.jpg" alt="" class="rounded"></figure>
    <div class="card-body p-4">
      <h2 class="card-title">{{ courseName }}</h2>
      <div class="justify-center card-actions">
        <button
          class="
            btn btn-outline
            bg-darkBlue
            text-white
            hover:bg-white
            hover:text-darkBlue
            hover:border-darkBlue
            rounded
          "
        >
          <router-link
            :to="{ name: 'Detail', params: { course: courseParams } }"
          >
            Eğİtİm Detayları
          </router-link>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";
export default {
  props: ["courseName", "courseParams"],
  mounted() {
    AOS.init();
  },
};
</script>
<style scoped>
.card:hover {
  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}
</style>
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/tailwind.css'
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)
Vue.config.productionTip = false
export const eventBus = new Vue();

Vue.prototype.$courses = [
  {
    id: 1,
    courseName: "Basic Fundamentals for Software Engineering",
    description:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Id debitis quae necessitatibus veritatis nulla tenetur sunt quis iste dolor. Minus corrupti impedit quo facere fugit molestiae corporis a quis tempore.",
    features:
      "Category Therapy Duration: 2 weeks Max Retakes: 3 Pass Percentage: 90 Lessons: 9 Quizzes: 2",
  },
  {
    id: 2,
    courseName: "Enhancing Adobe Photoshop CC 2020 Skills",
    description:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Id debitis quae necessitatibus veritatis nulla tenetur sunt quis iste dolor. Minus corrupti impedit quo facere fugit molestiae corporis a quis tempore.",
    features:
      "Category Therapy Duration: 2 weeks Max Retakes: 3 Pass Percentage: 90 Lessons: 9 Quizzes: 2",
  },
  {
    id: 3,
    courseName: "HTML, CSS, and Javascript for Web Developers",
    description:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Id debitis quae necessitatibus veritatis nulla tenetur sunt quis iste dolor. Minus corrupti impedit quo facere fugit molestiae corporis a quis tempore.",
    features:
      "Category Therapy Duration: 2 weeks Max Retakes: 3 Pass Percentage: 90 Lessons: 9 Quizzes: 2",
  },
  {
    id: 4,
    courseName: "Introducing to Programming with WordPress",
    description:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Id debitis quae necessitatibus veritatis nulla tenetur sunt quis iste dolor. Minus corrupti impedit quo facere fugit molestiae corporis a quis tempore.",
    features:
      "Category Therapy Duration: 2 weeks Max Retakes: 3 Pass Percentage: 90 Lessons: 9 Quizzes: 2",
  },
  {
    id: 5,
    courseName: "Basic Fundamentals for Software Engineering",
    description:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Id debitis quae necessitatibus veritatis nulla tenetur sunt quis iste dolor. Minus corrupti impedit quo facere fugit molestiae corporis a quis tempore.",
    features:
      "Category Therapy Duration: 2 weeks Max Retakes: 3 Pass Percentage: 90 Lessons: 9 Quizzes: 2",
  },
  {
    id: 6,
    courseName: "HTML, CSS, and Javascript for Web Developers",
    description:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Id debitis quae necessitatibus veritatis nulla tenetur sunt quis iste dolor. Minus corrupti impedit quo facere fugit molestiae corporis a quis tempore.",
    features:
      "Category Therapy Duration: 2 weeks Max Retakes: 3 Pass Percentage: 90 Lessons: 9 Quizzes: 2",
  },
  {
    id: 7,
    courseName: "HTML, CSS, and Javascript for Web Developers",
    description:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Id debitis quae necessitatibus veritatis nulla tenetur sunt quis iste dolor. Minus corrupti impedit quo facere fugit molestiae corporis a quis tempore.",
    features:
      "Category Therapy Duration: 2 weeks Max Retakes: 3 Pass Percentage: 90 Lessons: 9 Quizzes: 2",
  },
]

new Vue({
  el: "#app",
  router,
  render: h => h(App)
});
// new Vue({
//   router,
//   render: h => h(App)
// }).$mount('#app')

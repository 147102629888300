<template>
  <div class="header bg-white px-3 sm:px-5 md:px-10 lg:px-20 xl:px-40 py-5">
    <div class="flex items-center justify-between">
      <div class="logo flex items-center">
        <router-link to="/">
          <img :src="require('../img/DetayAkademi.png')" alt="" />
        </router-link>
      </div>
      <div class="header-bottom flex items-center justify-around">
        <div class="nav w-full">
          <ul class="flex flex-row text-text2 font-medium text-base">
            <router-link
              to="/"
              tag="li"
              active-class="text-routerBlue router-border opacity-100"
              exact
              class="mr-5 pb-1 opacity-80"
            >
              <a>Anasayfa</a>
            </router-link>
            <div class="" id="educationDetailRoute">
              <router-link
                to="/Detail/"
                active-class="text-routerBlue   router-border opacity-100"
                tag="li"
                class="pb-1 mr-5 opacity-80"
              >
                <a>Eğitim Detayları</a>
              </router-link>
            </div>

            <router-link
              to="/Contact"
              active-class="text-routerBlue  router-border opacity-100"
              tag="li"
              class="pb-1 opacity-80"
            >
              <a>İletişim</a>
            </router-link>
          </ul>
        </div>
      </div>
      <div
        id="mySidenav"
        class="
          sidenav
          transition-all
          fixed
          z-20
          h-full
          w-0
          top-0
          right-0
          bg-gray-50
          overflow-hidden
          pt-16
        "
      >
        <a
          href="javascript:void(0)"
          class="closebtn absolute top-0 text-3xl"
          @click="closeNav()"
        >
          <span class="text-4xl mt-3 mb-3"> &times;</span>
        </a>
        <div class="sidebar-router">
          <router-link
            to="/"
            class="side-class"
            active-class="side-active-class"
            exact
            >Anasayfa</router-link
          >
          <div id="educationDetailRoute" class="w-full">
            <router-link
              to="/Detail"
              class="side-active-class side-class w-full"
              exact
            >
              Eğitim Detayları
            </router-link>
          </div>
          <router-link
            to="/Contact"
            class="side-class"
            active-class="side-active-class"
            exact
            >İletişim</router-link
          >
        </div>
      </div>
      <span class="bars cursor-pointer" @click="openNav()"
        ><i class="fas fa-bars text-3xl side-active-class"></i
      ></span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      courses: this.$courses,
      pathName: false,
    };
  },
  methods: {
    openNav() {
      document.getElementById("mySidenav").style.width = "250px";
      document.getElementById("mySidenav").style.boxShadow =
        "rgba(14, 30, 37, 0.32) 0px 2px 16px 10px";
    },
    closeNav() {
      document.getElementById("mySidenav").style.width = "0";
      document.getElementById("mySidenav").style.boxShadow = "none";
    },
  },
  mounted() {
    const path = window.location.pathname;
    var educationDetailRoute = document.querySelectorAll(
      "#educationDetailRoute"
    )[0];
    var sideEducationDetailRoute = document.querySelectorAll(
      "#educationDetailRoute"
    )[1];

    if (path.includes("/Detail")) {
      educationDetailRoute.className = "flex";
      sideEducationDetailRoute.className = "flex";
    } else {
      educationDetailRoute.className = "hidden";
      sideEducationDetailRoute.className = "hidden";
    }
    console.log(educationDetailRoute);
  },
  created() {
    ////////////////////////
    var _this = this;
    this.courseName = this.$route.params.course;
    this.courses.forEach((course) => {
      if (course.courseName == _this.courseName) {
        _this.id = course.id;
        _this.courseName = course.courseName;
        _this.description = course.description;
        _this.features = course.features;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.sidenav {
  a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 18px;
    color: #575454;
    display: block;
    transition: 0.3s;
  }
  .side-class {
    border-bottom: 1px solid #e7e5e4;
    &:hover {
      background-color: #036e9a;
      color: white !important;
    }
  }
}
.side-active-class {
  color: #036e9a !important;
}

.sidebar-router {
  margin-top: 18px;
}
.logo {
  max-width: 250px;
}
.router-border {
  border-bottom: 3px solid #036e9a;
}
@media only screen and (max-width: 600px) {
  .header-top .social-media,
  .header-bottom {
    display: none;
  }
}
@media only screen and (min-width: 600px) {
  .bars {
    display: none;
  }
}
@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}
</style>
<template>
  <div class="relative z-10">
    <div class="banner w-full">
      <div class="particle-container w-full z-10">
        <vue-particles
          color="#ffffff"
          linesColor="#ffffff"
          :moveSpeed="4"
          :particlesNumber="150"
        ></vue-particles>
      </div>
      <div
        class="
          relative
          flex
          justify-between
          px-3
          sm:px-5
          md:px-10
          lg:px-20
          xl:px-40
          z-20
        "
      >
        <div class="text-white py-20">
          <h2 class="font-bold text-5xl">
            DETAY AKADEMİ'YE <br />
            HOŞGELDİNİZ
          </h2>
          <h3 class="text-3xl my-5 w-5/6 leading-9">
            Kurumumuzca düzenlenen birçok yararlı eğitim sizleri bekliyor.
          </h3>
          <h4 class="text-xl w-4/5 leading-9 opacity-70">
            Eğitimlerimiz hakkında detaylı bilgilere ulaşarak başvuruda
            bulunabilir, kişisel gelişiminize katkı sağlayabilirsiniz.
          </h4>
        </div>
        <div class="py-10 hidden sm:block slide-top">
          <img
            style="max-width: 300px"
            :src="require('../img/DetayAkademiAnasayfa.png')"
            alt=""
          />
        </div>
      </div>
    </div>

    <div
      class="
        courses
        relative
        z-10
        py-10
        px-3
        sm:px-5
        md:px-10
        lg:px-20
        xl:px-40
      "
    >
      <div class="courses-title flex flex-col items-center text-center">
        <h2 class="text-3xl font-semibold mb-5">
          Öne Çıkan Kurslarımızı<br />
          Keşfedin
        </h2>
        <p class="">
          Çalışanlarımız Tarafından Birçok Alanda Hazırlanan Yararlı
          İçeriklerimizi İnceleyebilir,<br />
          Eğitimlerimize Başvurabilirsiniz
        </p>
        <div class="courses-border"></div>
      </div>
      <div class="z-10 relative py-10 flex items-center justify-center">
        <div class="section grid">
          <app-CourseBoxItem
            class=""
            v-for="course in courses"
            :key="course.id"
            :courseName="course.courseName"
            :courseParams="course.courseName"
          ></app-CourseBoxItem>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import { eventBus } from "../main";
import VueParticles from "vue-particles/src/vue-particles/vue-particles.vue";
import CourseBoxItem from "./CourseBoxItem";
export default {
  components: {
    appCourseBoxItem: CourseBoxItem,
    "vue-particles": VueParticles,
  },
  data() {
    return {
      courses: this.$courses,
    };
  },
};
</script>
<style lang="scss">
.banner {
  background-image: url(../img/slider1.png);
  overflow: hidden;
  .particle-container {
    position: absolute;
    left: 0;
    right: 0;
    top: 80px;
    bottom: 40px;
    overflow: hidden;
    z-index: 0;
  }
}

.slide-top {
  animation: slide-top 2s ease-in-out infinite alternate-reverse both;
}

@keyframes slide-top {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-30px);
  }
}
@media only screen and (min-width: 500px) {
  .grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media only screen and (min-width: 900px) {
  .grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
</style>
